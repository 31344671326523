import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';
const loginStorage = secureLocalStorage.getItem('authenticated');
const loginData = JSON.parse(loginStorage);
// Slice
const slice = createSlice({
  name: 'categorySlice',
  initialState: {
    categoryListData: null,
    subCategoryListData: null,
    imageListData: [],
    imageNameList: [],
    productId: null,
    getProductVerifyData: null,
    detailProductId: null,
    productDetails: null,
    reviewImagesName: [],
    reviewImagesPath: [],
    reviewImagesData: null,
    reviewBrand: [],
    reviewCategory: [],
    subCategoryData: [],
    categoryArray: [],
    productCategoryArray: [],
    subCatData: null
  },
  reducers: {
    categoryListSuccess: (state, action) => {
      state.categoryListData = action.payload.data;
    },
    subCategoryListSuccess: (state, action) => {
      let categoriesData = action.payload.data;
      let newSubItem = categoriesData.map((catItem) => {
        catItem['subCatArray'] = catItem.subCatArray.map((item) =>
          Object.assign({}, item, { isSelected: false })
        );
        return catItem;
      });

      state.subCategoryListData = newSubItem;
    },
    removeSubCategorySuccess: (state, action) => {
      let reqId = action.payload;
      state.subCategoryListData = state.subCategoryListData.filter(
        (item) => item.categoryId !== reqId
      );
    },
    imagesListSuccess: (state, action) => {
      if (state.imageListData.length < 9) {
        state.imageListData = state.imageListData.concat(action.payload[0].accessUrl);
        state.imageNameList = state.imageNameList.concat(action.payload[0].fileName);
      }
    },
    reviewImagesListSuccess: (state, action) => {
      state.reviewImagesData = {
        fileName: action.payload[0].fileName,
        fullPath: action.payload[0].accessUrl
      };
    },
    deleteImagesSuccess: (state, action) => {
      let deleteIndex = action.payload;
      state.imageListData = state.imageListData.filter((item, index) => index !== deleteIndex);
      state.imageNameList = state.imageNameList.filter((item, index) => index !== deleteIndex);
    },
    addproductSuccess: (state, action) => {
      state.productId = action.payload.id;
      state.productDetails = action.payload;
    },
    getProductVerifyData: (state, action) => {
      state.getProductVerifyData = action.payload;
    },
    getproductIdSuccess: (state, action) => {
      state.detailProductId = action.payload.data.Id;
    },
    getproductDetailSuccess: (state, action) => {
      state.productDetails = action.payload;
    },
    addReviewImagesSuccess: (state, action) => {
      let objIndex = state.reviewImagesName.findIndex((item) => item === action.payload.fileName);
      if (objIndex < 0) {
        state.reviewImagesPath = state.reviewImagesPath.concat(action.payload.fullPath);
        state.reviewImagesName = state.reviewImagesName.concat(action.payload.fileName);
      }
    },
    removeReviewImagesSuccess: (state, action) => {
      let deleteIndex = action.payload;
      state.reviewImagesPath = state.reviewImagesPath.filter(
        (item, index) => index !== deleteIndex
      );
      state.reviewImagesName = state.reviewImagesName.filter(
        (item, index) => index !== deleteIndex
      );
    },
    addReviewBrandSuccess: (state, action) => {
      let index = action.payload.data.index;
      state.reviewBrand[index] = action.payload.data.data;
    },
    removeReviewBrandSuccess: (state, action) => {
      let deleteIndex = action.payload;
      state.reviewBrand = state.reviewBrand.filter((item, index) => index !== deleteIndex);
    },
    addReviewCategorySuccess: (state, action) => {
      state.reviewCategory = state.reviewCategory.concat(action.payload.data.data);
    },
    clearReviewImagesSuccess: (state, action) => {
      state.reviewImagesPath = action.payload.data.data;
      state.reviewImagesName = action.payload.data.data;
    },
    removeReviewCategorySuccess: (state, action) => {
      let deleteIndex = action.payload;
      state.reviewCategory = state.reviewCategory.filter((item, index) => index !== deleteIndex);
      state.subCategoryListData = state.subCategoryListData.filter(
        (item, index) => index !== deleteIndex
      );
    },
    reviewSubcategoryArraySuccess: (state, action) => {
      let index = action.payload.data.index;
      state.categoryArray[index] = action.payload.data.data;
    },
    updateReviewCategorySuccess: (state, action) => {
      let index = action.payload.data.index;
      state.reviewCategory[index] = action.payload.data.data;
    },
    addCategorySuccess: (state, action) => {
      state.productCategoryArray = state.productCategoryArray.concat(action.payload.data.data);
    },
    deleteCategorySuccess: (state, action) => {
      let deleteId = action.payload.data.data;
      state.productCategoryArray = state.productCategoryArray.filter(
        (item) => item.id !== deleteId
      );
    },
    clearCategorySuccess: (state, action) => {
      state.productCategoryArray = action.payload;
    },
    clearReviewCategorySuccess: (state, action) => {
      state.reviewCategory = action.payload;
      state.categoryArray = action.payload;
    },
    updateSubCategoryData: (state, action) => {
      let subCat = action.payload.newSubCat;
      let catId = action.payload.categoryId;
      const index = state.subCategoryListData.findIndex((item) => item.categoryId === catId);
      state.subCategoryListData[index]['subCatArray'] = subCat;
    },
    setCoverImageSuccess: (state, action) => {
      let index = action.payload.index;
      let data = action.payload.data;
      let imageName = action.payload.imageName;
      state.imageListData.splice(index, 1);
      state.imageListData.unshift(data);
      state.imageNameList.splice(index, 1);
      state.imageNameList.unshift(imageName);
    },
    setReviewCoverImageSuccess: (state, action) => {
      let index = action.payload.index;
      let data = action.payload.data;
      let imageName = action.payload.imageName;
      state.reviewImagesPath.splice(index, 1);
      state.reviewImagesPath.unshift(data);
      state.reviewImagesName.splice(index, 1);
      state.reviewImagesName.unshift(imageName);
    },
    imageMoveForwardSuccess: (state) => {
      let end = state.imageListData[state.imageListData.length - 1];
      let end1 = state.imageNameList[state.imageNameList.length - 1];

      for (let i = state.imageListData.length - 1; i > 0; i--) {
        state.imageListData[i] = state.imageListData[i - 1];
        state.imageNameList[i] = state.imageNameList[i - 1];
      }

      state.imageListData[0] = end;
      state.imageNameList[0] = end1;
    },
    imageMoveBackwardSuccess: (state) => {
      let first = state.imageListData[0];
      let first1 = state.imageNameList[0];
      for (let i = 0; i < state.imageListData.length - 1; i++) {
        state.imageListData[i] = state.imageListData[i + 1];
        state.imageNameList[i] = state.imageNameList[i + 1];
      }

      state.imageListData[state.imageListData.length - 1] = first;
      state.imageNameList[state.imageNameList.length - 1] = first1;
    },
    reviewImageMoveForwardSuccess: (state) => {
      let end = state.reviewImagesPath[state.reviewImagesPath.length - 1];
      let end1 = state.reviewImagesName[state.reviewImagesName.length - 1];

      for (let i = state.reviewImagesPath.length - 1; i > 0; i--) {
        state.reviewImagesPath[i] = state.reviewImagesPath[i - 1];
        state.reviewImagesName[i] = state.reviewImagesName[i - 1];
      }

      state.reviewImagesPath[0] = end;
      state.reviewImagesName[0] = end1;
    },
    reviewImageMoveBackwardSuccess: (state) => {
      let first = state.reviewImagesPath[0];
      let first1 = state.reviewImagesName[0];
      for (let i = 0; i < state.reviewImagesPath.length - 1; i++) {
        state.reviewImagesPath[i] = state.reviewImagesPath[i + 1];
        state.reviewImagesName[i] = state.reviewImagesName[i + 1];
      }

      state.reviewImagesPath[state.reviewImagesPath.length - 1] = first;
      state.reviewImagesName[state.reviewImagesName.length - 1] = first1;
    },

    imagelistDataSuccess: (state, action) => {
      let index = state.imageNameList.findIndex((item) => item == action.payload.data.fileName);
      if (index < 0) {
        state.imageListData = state.imageListData.concat(action.payload.data.fullPath);
        state.imageNameList = state.imageNameList.concat(action.payload.data.fileName);
      }
    },

    productImagesDataSuccess: (state, action) => {
      state.imageListData = action.payload;
      state.imageNameList = action.payload;
    },
    isProductFavouriteSuccess: (state, action) => {
      state.productDetails.isFavorite = action.payload.isFavorite;
    },
    clearProductDetailsSuccess: (state, action) => {
      state.productDetails = action.payload;
    },
    addSubCategorySuccess: (state, action) => {
      state.subCatData = action.payload;
    },
    clearSubCatDataSuccess: (state, action) => {
      state.subCatData = action.payload;
    },
    clearSubcategoryDataSuccess: (state, action) => {
      state.subCategoryListData = action.payload;
    },
    clearProductdetail: (state) => {
      state.productDetails = null;
    },
    clearImageListDataSuccess: (state) => {
      state.imageListData = [];
      state.imageNameList = [];
    }
  }
});
const {
  categoryListSuccess,
  subCategoryListSuccess,
  removeSubCategorySuccess,
  imagesListSuccess,
  deleteImagesSuccess,
  addproductSuccess,
  clearProductdetail,
  getProductVerifyData,
  getproductIdSuccess,
  getproductDetailSuccess,
  reviewImagesListSuccess,
  addReviewImagesSuccess,
  removeReviewImagesSuccess,
  addReviewBrandSuccess,
  removeReviewBrandSuccess,
  addReviewCategorySuccess,
  clearReviewImagesSuccess,
  removeReviewCategorySuccess,
  reviewSubcategoryArraySuccess,
  updateReviewCategorySuccess,
  addCategorySuccess,
  deleteCategorySuccess,
  clearCategorySuccess,
  clearReviewCategorySuccess,
  updateSubCategoryData,
  setCoverImageSuccess,
  setReviewCoverImageSuccess,
  imageMoveForwardSuccess,
  imageMoveBackwardSuccess,
  reviewImageMoveBackwardSuccess,
  reviewImageMoveForwardSuccess,
  imagelistDataSuccess,
  productImagesDataSuccess,
  isProductFavouriteSuccess,
  clearProductDetailsSuccess,
  addSubCategorySuccess,
  clearSubCatDataSuccess,
  clearSubcategoryDataSuccess,
  clearImageListDataSuccess
} = slice.actions;

export const updateNewSubCatgory = (data) => (dispatch) => {
  dispatch(updateSubCategoryData(data));
};

export const categoryList = (data) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.categoryList}?language=${data.language}`)
    .then((response) => {
      if (response) {
        dispatch(categoryListSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const addSubCategory = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.addSubCategory}`, data)
    .then((response) => {
      console.log('response', response);
      let result = response.data;
      if (result) {
        dispatch(addSubCategorySuccess(result.date));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const subCategoryList = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.subCategoryList}`, data)
    .then((response) => {
      let result = response.data;
      if (result) {
        dispatch(subCategoryListSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const removeSubCategory = (data) => (dispatch) => {
  dispatch(removeSubCategorySuccess(data));
};
export const imagesList = (data, folderName, language, setShowCropDialog) => (dispatch) => {
  api
    .post(`${ADRENAAPI.imageUpload}/${folderName}?previousFile=search&language=${language}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      let result = response.data;
      if (result) {
        dispatch(imagesListSuccess(result.data));
        if (setShowCropDialog) {
          setShowCropDialog(false);
        }
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const reviewImagesList = (data, folderName, language, setShowCropDialog) => (dispatch) => {
  api
    .post(`${ADRENAAPI.imageUpload}/${folderName}?previousFile=search&language=${language}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      let result = response.data;
      if (result) {
        dispatch(reviewImagesListSuccess(result.data));
        if (setShowCropDialog) {
          setShowCropDialog(false);
        }
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};

export const deleteImages = (data, index) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.imageDelete}`, data)
    .then((response) => {
      if (response.data.status === 1) {
        dispatch(deleteImagesSuccess(index));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getProductContactVerifyOtp = (data, setDialogId) => async () => {
  api
    .post(`${ADRENAAPI.getProductContactVerifyOtp}`, data)
    .then((response) => {
      if (response.data.status) {
        toast.success(response.data.message);
        if (setDialogId) {
          setDialogId(1);
        }
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getProductContactVerifyStatus =
  (data, onClose, dialogData, setAdFormData, myStoreContact) => async (dispatch) => {
    api
      .post(`${ADRENAAPI.getProductContactVerifyStatus}`, data)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          if (dialogData) {
            if (myStoreContact === 'whatsApp') {
              setAdFormData((setAdFormData) => ({
                ...setAdFormData,
                storeWhatsappNo: dialogData?.phone,
                storeWhatsappCountryCode: dialogData?.dialCode
              }));
            } else if (myStoreContact === 'phone') {
              setAdFormData((setAdFormData) => ({
                ...setAdFormData,
                storePhoneNo: dialogData?.phone,
                storePhoneNoCountryCode: dialogData?.countryCode
              }));
            } else if (myStoreContact === 'email') {
              setAdFormData((setAdFormData) => ({
                ...setAdFormData,
                storeEmail: dialogData?.email
              }));
            } else {
              setAdFormData((setAdFormData) => ({
                ...setAdFormData,
                whatsappNumber: dialogData?.phone
              }));
            }
          }
          dispatch(getProductVerifyData(response.data.data));
          onClose();
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch(() => {});
  };
export const addproduct =
  (data, navigate, path, productImagesData, setIsloading, productDetail) => async (dispatch) => {
    api
      .post(`${ADRENAAPI.addproduct}`, data)
      .then((response) => {
        if (response.data.status === 1) {
          if (productDetail) {
            dispatch(clearProductdetail());
          } else {
            dispatch(addproductSuccess(response.data.data));
          }

          if (navigate) {
            navigate(path);
          }
          if (productImagesData) {
            dispatch(productImagesData());
          }
          if (setIsloading) {
            setIsloading(false);
          }
        } else {
          toast.warning(response.data.message);
          setIsloading(false);
        }
      })
      .catch(() => {});
  };
export const getproductId = (data) => (dispatch) => {
  dispatch(getproductIdSuccess({ data: data }));
};
export const getproductDetail = (data, language, setLoader) => async (dispatch) => {
  api
    .get(
      `${ADRENAAPI.getproductDetail}/${data}?userId=${loginData?.data?.id}&language=${language}`,
      {
        headers: {
          accept: '*',
          key: process.env.REACT_APP_AUTH_API_KEY
        }
      }
    )
    .then((response) => {
      if (response.data.status) {
        dispatch(getproductDetailSuccess(response.data.data));
        setLoader(false);
      }
    })
    .catch(() => {});
};
export const clearProductDetails = () => (dispatch) => {
  dispatch(clearProductDetailsSuccess(null));
};
export const clearSubCatData = () => (dispatch) => {
  dispatch(clearSubCatDataSuccess(null));
};
export const addReviewImages = (data) => (dispatch) => {
  dispatch(addReviewImagesSuccess(data));
};
export const removeReviewImages = (data) => (dispatch) => {
  dispatch(removeReviewImagesSuccess(data));
};
export const addReviewBrand = (data) => (dispatch) => {
  dispatch(addReviewBrandSuccess({ data: data }));
};
export const removeReviewBrand = (data) => (dispatch) => {
  dispatch(removeReviewBrandSuccess(data));
};
export const addReviewCategory = (data) => (dispatch) => {
  dispatch(addReviewCategorySuccess({ data: data }));
};
export const clearReviewImages = (data) => (dispatch) => {
  dispatch(clearReviewImagesSuccess({ data: data }));
};
export const removeReviewCategory = (data) => (dispatch) => {
  dispatch(removeReviewCategorySuccess(data));
};
export const reviewSubcategoryArray = (data) => (dispatch) => {
  dispatch(reviewSubcategoryArraySuccess({ data: data }));
};
export const updateReviewCategory = (data) => (dispatch) => {
  dispatch(updateReviewCategorySuccess({ data: data }));
};
export const addCategory = (data) => (dispatch) => {
  dispatch(addCategorySuccess({ data: data }));
};
export const deleteCategory = (data) => (dispatch) => {
  dispatch(deleteCategorySuccess({ data: data }));
};
export const clearCategory = (data) => (dispatch) => {
  dispatch(clearCategorySuccess(data));
};
export const clearReviewCategory = (data) => (dispatch) => {
  dispatch(clearReviewCategorySuccess(data));
};
export const setCoverImage = (imageName, data, index) => (dispatch) => {
  dispatch(setCoverImageSuccess({ imageName: imageName, data: data, index: index }));
};
export const setReviewCoverImage = (imageName, data, index) => (dispatch) => {
  dispatch(setReviewCoverImageSuccess({ imageName: imageName, data: data, index: index }));
};
export const imageMoveForward = () => (dispatch) => {
  dispatch(imageMoveForwardSuccess());
};
export const imageMoveBackward = () => (dispatch) => {
  dispatch(imageMoveBackwardSuccess());
};
export const reviewImageMoveForward = () => (dispatch) => {
  dispatch(reviewImageMoveForwardSuccess());
};
export const reviewImageMoveBackward = () => (dispatch) => {
  dispatch(reviewImageMoveBackwardSuccess());
};
export const imagelistData = (data) => (dispatch) => {
  dispatch(imagelistDataSuccess(data));
};
export const clearImageListData = () => (dispatch) => {
  dispatch(clearImageListDataSuccess());
};
export const productImagesData = () => (dispatch) => {
  dispatch(productImagesDataSuccess([]));
};
export const clearSubcategoryData = () => (dispatch) => {
  dispatch(clearSubcategoryDataSuccess(null));
};
export const isProductFavourite = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.productFavourite}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(isProductFavouriteSuccess(result.data));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export default slice.reducer;
