import secureLocalStorage from 'react-secure-storage';
import CryptoJS from 'crypto-js';

const loginStorage = secureLocalStorage.getItem('authenticated');
export const loginData = JSON.parse(loginStorage);

export const encrypt = (secretKey) => {
  const cipherText = CryptoJS.AES.encrypt(secretKey, 'secret key 123').toString();
  let encodeText = encodeURIComponent(cipherText);
  return encodeText;
};

export const decrypt = (secretKey) => {
  let decodeText = decodeURIComponent(secretKey);
  var bytes = CryptoJS.AES.decrypt(decodeText, 'secret key 123');
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
