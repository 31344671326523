import ScrollToTop from 'components/scrollToTop';
import { onMessageListener, requestForToken } from './fireBase';
import AdBrand from 'pages/selling/adDetails/adBrand';
import AdSuccessFull from 'pages/selling/adSuccessfull';
import { Suspense, lazy, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlankScrren from 'components/blankScrren';
import TagManager from 'react-gtm-module';
import { loginData } from 'constant';
import Notifications from 'components/Notifications/Notifications';

const tagManagerArgs = {
  gtmId: 'GTM-PBH3RGV5',
  userId: loginData ? loginData?.data?.id : '',
  dataLayer: {
    userProject: 'Adrena'
  }
};
TagManager.initialize(tagManagerArgs);
const App = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }
  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);
  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    try {
      window.Iugu.setAccountID('82F8E4F2ABE840B1BE51B5B209914BC9');
      window.Iugu.setTestMode(true);
      window.Iugu.setup();
    } catch (error) {
      console.log('Error', error);
    }
  }, []);

  const Home = lazy(() => import('./pages/home/index'));
  const AuthView = lazy(() => import('./pages/auth/index'));
  const CreateAd = lazy(() => import('./pages/selling/createAd/index'));
  const SellingCategory = lazy(() => import('./pages/selling/category/index'));
  const SubCategory = lazy(() => import('./pages/selling/subCategory/index'));
  const OtherSubCategory = lazy(() => import('./pages/selling/subCategory/others'));
  const AdTitle = lazy(() => import('./pages/selling/adDetails/adTitle'));
  const AdPrice = lazy(() => import('./pages/selling/adDetails/adPrice'));
  const AdDescription = lazy(() => import('./pages/selling/adDetails/adDescription'));
  const AdLocation = lazy(() => import('./pages/selling/adDetails/adLocation'));
  const AdPhoto = lazy(() => import('./pages/selling/adDetails/adPhoto'));
  const AdContact = lazy(() => import('./pages/selling/adDetails/adContact'));
  const AdReview = lazy(() => import('./pages/selling/reviewAd/adReview'));
  const PreView = lazy(() => import('./pages/selling/preview/index'));
  const VerifyBadge = lazy(() => import('./pages/selling/verifyBadge/index'));
  const ChoosePlan = lazy(() => import('./pages/selling/choosePlan/index'));
  const AdPayment = lazy(() => import('./pages/selling/conclusion/payment'));
  const AdPublish = lazy(() => import('./pages/selling/publishNow'));
  const MyAds = lazy(() => import('./pages/myAds/index'));
  const EditMyAd = lazy(() => import('./pages/myAds/subComponent/editAd'));
  const PersonalAccount = lazy(() => import('./pages/myAccount/personalAccount/index'));
  const AccountPersonalInfo = lazy(() => import('./pages/myAccount/personalInfo/index'));
  const AccountBussinessInfo = lazy(() => import('./pages/myAccount/bussinessInfo/index'));
  const AccountSetting = lazy(() => import('./pages/myAccount/accountSetting/index'));
  const IdVerfication = lazy(() => import('./pages/myAccount/verifyBadge/index'));
  const BadgePayment = lazy(() => import('./pages/myAccount/verifyBadge/paymentPage'));
  const BadgeDocument = lazy(() => import('./pages/myAccount/verifyBadge/documentation'));
  const BussinessAccount = lazy(() => import('./pages/myAccount/bussinessAccount/index'));
  const PreLaunch = lazy(() => import('./pages/buying/prelaunch/index'));
  const BuyingCategory = lazy(() => import('./pages/buying/category/index'));
  const BuyingSubCategory = lazy(() => import('./pages/buying/subCategory/index'));
  const ProductList = lazy(() => import('./pages/buying/prdouctList/index'));
  const ProductDetail = lazy(() => import('./pages/buying/productDetail/index'));
  const Messages = lazy(() => import('./pages/message/index'));
  const SellerProductList = lazy(() => import('./pages/buying/prdouctList/selllerProductList'));
  const WishList = lazy(() => import('./pages/wishlist/index'));
  const WhoWeAre = lazy(() => import('./pages/whoWeAre/index'));
  const NeedHelp = lazy(() => import('./pages/needHelp/index'));
  const NotFoundPage = lazy(() => import('./pages/404Page/index'));
  const MyStore = lazy(() => import('./pages/myStore/index'));
  const StoreSetting = lazy(() => import('./pages/myStore/storeSetting/index'));
  const Contract = lazy(() => import('./pages/contractTemplate/index'));
  const AdminView = lazy(() => import('./pages/adminView/index'));
  const History = lazy(() => import('./pages/history/index'));
  const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/index'));
  const TermsAndCondition = lazy(() => import('./pages/termsAndCondition/index'));
  const CreateStore = lazy(() => import('./pages/selling/createStore/index'));

  return (
    <>
      <Notifications />
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Toaster />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              exact
              path="/auth"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AuthView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <CreateAd />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/:type/category"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <SellingCategory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/:type/:subCat"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <SubCategory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/sub-category/others/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <OtherSubCategory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-title/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdTitle />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-price/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdPrice />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-description/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdDescription />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-location/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdLocation />{' '}
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-photos/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdPhoto />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-brand/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdBrand />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-contacts/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdContact />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/create-ad/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdReview />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-preview/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <PreView />
                </Suspense>
              }
            />{' '}
            <Route
              exact
              path="/sell-equipment/choose-plan/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <ChoosePlan />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/verify-badge/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <VerifyBadge />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/conclusion/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdPayment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-publish/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdPublish />
                </Suspense>
              }
            />
            <Route
              exact
              path="/sell-equipment/ad-ready/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdSuccessFull />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-ads"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <MyAds />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-ads/:adName/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <EditMyAd />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/personal-account"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <PersonalAccount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/personal-information"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AccountPersonalInfo />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/bussiness-information"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AccountBussinessInfo />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/bussiness-account"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <BussinessAccount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/account-setting"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AccountSetting />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/verify-badge"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <IdVerfication />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/verify-badge/payment"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <BadgePayment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-account/verify-badge/documentation"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <BadgeDocument />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buyingPreLaunch"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <PreLaunch />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/category"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <BuyingCategory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/:category/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <BuyingSubCategory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/admin/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <AdminView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/:catName/:subCatName/:catId/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <ProductList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/:catName/:subCat/:catId"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <ProductList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <ProductList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/:productName/details/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <ProductDetail />
                </Suspense>
              }
            />
            <Route
              exact
              path="/messages"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <Messages />
                </Suspense>
              }
            />
            <Route
              exact
              path="/messages/:chatType/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <Messages />
                </Suspense>
              }
            />
            <Route
              exact
              path="/buy/seller-all-ads/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <SellerProductList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/wishlist"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <WishList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/who-we-are"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <WhoWeAre />
                </Suspense>
              }
            />
            <Route
              exact
              path="/needHelp"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <NeedHelp />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-gallery"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <MyStore />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-gallery/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <MyStore />
                </Suspense>
              }
            />
            <Route
              exact
              path="/adrena.com.br/:customUrl"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <MyStore />
                </Suspense>
              }
            />
            <Route
              exact
              path="/my-gallery/setting"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <StoreSetting />
                </Suspense>
              }
            />
            <Route
              exact
              path="/contract/:type/:id"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <Contract />
                </Suspense>
              }
            />
            <Route
              exact
              path="/history"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <History />
                </Suspense>
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              exact
              path="/terms-condition"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <TermsAndCondition />
                </Suspense>
              }
            />
            <Route
              exact
              path="*"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <NotFoundPage />
                </Suspense>
              }
            />
            <Route
              exact
              path="/create-store"
              element={
                <Suspense fallback={<BlankScrren />}>
                  <CreateStore />
                </Suspense>
              }
            />
          </Routes>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};

export default App;
