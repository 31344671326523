import {
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import SellingNavbar from '../SellingNavbar';
import { useNavigate, useParams } from 'react-router-dom';
// import { Icon } from '@iconify/react';
import { boldFont, formFont, smallFont } from 'pages/myAccount/style';
import { Icon } from '@iconify/react';
import AdrenaLabel from 'components/label';
import AdrenaButton from 'components/button';
import AdrenaRatingDialog from 'components/dialogs/adrenaRatingDialog';
import { clearProductDetails } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import ShareAdDialog from 'components/dialogs/shareAd';
import { getproductDetail } from 'store';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import image from '../../../assets/img/noimage.png';
import { addAdrenaRating } from 'store';
import { userAdrenaRating } from 'store';
import TagManager from 'react-gtm-module';
import { decrypt, encrypt } from 'constant';
import { toast } from 'react-toastify';
import ViewInvoice from 'components/viewInvoice';

const tagManagerArgs = {
  dataLayer: {
    userProject: 'Adrena',
    page: 'Adrena-AdCreatedSuccesFully'
  }
};
export default function AdSuccessFull() {
  TagManager.dataLayer(tagManagerArgs);

  const { id } = useParams();
  const navigate = useNavigate();
  var theme = useTheme();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [ratingDialog, setRatingDialog] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const { productDetails } = useSelector((state) => state.categorySlice);
  const { adrenaRatingData } = useSelector((state) => state.productSlice);
  const [ratingData, setRatingData] = useState({});
  useEffect(() => {
    dispatch(getproductDetail(decrypt(id), i18n.language));
  }, []);

  useEffect(() => {
    if (adrenaRatingData) {
      setRatingData(adrenaRatingData);
    }
  }, [adrenaRatingData]);

  useEffect(() => {
    dispatch(userAdrenaRating({ language: i18n?.language }));
  }, []);
  const handleBack = () => {
    navigate(`/sell-equipment/ad-publish/${encrypt(decrypt(id))}`);
  };

  const url = `${process.env.REACT_APP_URL}/buy/${productDetails?.title.replace(
    /\s+/g,
    '-'
  )}/details/${encrypt(decrypt(id))}`;
  const paymentData = JSON.parse(sessionStorage.getItem('paymentData'));
  const handleRating = () => {
    let obj = {
      review: ratingData?.review,
      rate: ratingData?.rate,
      language: i18n?.language
    };
    dispatch(addAdrenaRating(obj, setRatingDialog));
  };

  return (
    <>
      <AdrenaRatingDialog
        ShowDialog={ratingDialog}
        setShowDialog={setRatingDialog}
        onClick={handleRating}
        setRatingData={setRatingData}
        ratingData={ratingData}
      />
      <ShareAdDialog open={shareDialog} setOpen={setShareDialog} url={url} />
      {/* view invoice dialog */}
      <ViewInvoice
        url={paymentData?.invoiceUrl}
        open={showInvoice}
        handleClose={() => setShowInvoice(false)}
      />
      <Container
        component="main"
        maxWidth="xxl"
        className="container"
        sx={{ position: 'relative' }}>
        <CssBaseline />
        <SellingNavbar show={true} handleBack={handleBack} />
        <Divider
          sx={{
            background: '#323232',
            mt: 3,
            display: { sm: 'none', xs: 'none', md: 'block', lg: 'block' }
          }}
        />
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            mt: 8,
            pb: 5
          }}>
          {productDetails?.userPlan?.paymentMode != 'credit_card' &&
          productDetails?.userPlan?.paymentStatus === ('processing' || 'pending') ? (
            <>
              <Box
                sx={{
                  textAlign: 'left',
                  mx: isMobile ? 0 : 5,
                  backgroundColor: '#323232',
                  p: 3,
                  mt: 2
                }}>
                <Typography
                  sx={{
                    fontFamily: 'SetimoBold',
                    fontSize: '50px',
                    lineHeight: '55px',
                    fontWeight: 700,
                    color: '#E7E9EA'
                  }}>
                  {t('adSuccessfull.payNowAndPublished').toUpperCase()}
                </Typography>
                <Grid container spacing={3} sx={{ pt: 3 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AdrenaButton
                      onClick={() => setShowInvoice(true)}
                      fullWidth
                      text={t('adSuccessfull.btnPayNow')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AdrenaButton
                      onClick={() => {
                        navigator.clipboard.writeText(paymentData?.invoiceUrl);
                        toast.success(t('customUrl.copy'));
                      }}
                      fullWidth
                      text={t('adSuccessfull.invoiceUrl')}
                      style={{ background: 'transparent', border: '1px solid #E7E9EA' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: 'RocGrotesk',
                  fontSize: '64px',
                  lineHeight: '60px',
                  fontWeight: !isMobile ? 700 : isTablet ? 300 : 100,
                  color: '#E7E9EA'
                }}>
                {t('selling.adPublishedHeading')}
              </Typography>
              <Typography style={smallFont} sx={{ pt: 2, mx: isMobile ? 0 : 11 }}>
                {t('adSuccess.appearAfter24Hours')}
              </Typography>
              <Box
                sx={{
                  height: '329px',
                  mt: 2,
                  backgroundColor: '#323232',
                  p: 2,
                  mx: isMobile ? 0 : 11
                }}>
                <Box
                  sx={{
                    backgroundColor: 'black',
                    height: '297px',
                    mx: isMobile ? 3 : 8,
                    textAlign: 'left'
                  }}>
                  <img
                    src={
                      productDetails?.coverPhotoFullUrl ? productDetails?.coverPhotoFullUrl : image
                    }
                    alt=""
                    width={'100%'}
                    height={'164px'}
                  />
                  <Typography style={boldFont} sx={{ mx: 3, mt: 1 }}>
                    {productDetails?.title.toUpperCase()}
                  </Typography>
                  <Chip
                    style={formFont}
                    sx={{
                      borderRadius: 'unset',
                      backgroundColor: '#E01F26',
                      color: '#FFFFFF',
                      height: '21px',
                      ml: 3.3,
                      mt: 1
                    }}
                    label={productDetails?.conservationState}
                  />

                  {productDetails?.address && (
                    <Box sx={{ display: 'flex', ml: 3, mt: 1.2 }}>
                      <Icon icon="ion:location-sharp" color="#FFFFFF" height="16px" width="15px" />
                      &nbsp;
                      <Typography
                        style={formFont}
                        sx={{
                          mt: -0.1,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 170
                        }}>
                        {productDetails?.address}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  height: '160px',
                  mt: 2,
                  backgroundColor: '#323232',
                  p: 2,
                  mx: isMobile ? 0 : 11,
                  textAlign: 'left'
                }}>
                <Typography style={boldFont}>{t('selling.adShareOnSocialMedia')}</Typography>
                <Typography style={formFont}>{t('selling.repostonYourProfile')}</Typography>
                <Button
                  onClick={() => {
                    setShareDialog(true);
                  }}
                  startIcon={<Icon icon="solar:share-bold" />}
                  fullWidth
                  style={{
                    height: '40px',
                    backgroundColor: 'transparent',
                    border: '1px solid #FFFFFF',
                    marginTop: '0.5rem',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: 700,
                    fontFamily: 'setimo'
                  }}>
                  {t('selling.share')}
                </Button>
              </Box>
              <AdrenaLabel
                onClick={() => setRatingDialog(true)}
                buttonStyle={{ textDecoration: 'underline' }}
                text={t('selling.rateYourExperience')}
              />
              <AdrenaLabel
                buttonStyle={{ textDecoration: 'underline' }}
                onClick={() => navigate('/my-ads')}
                text={t('selling.seeMyAds')}
              />
              <Box sx={{ mx: isMobile ? 0 : 11 }}>
                <AdrenaButton
                  onClick={() => {
                    dispatch(clearProductDetails());
                    navigate('/sell-equipment');
                  }}
                  fullWidth
                  text={t('selling.adOtherEquipment')}
                />
              </Box>
            </>
          )}
        </Container>
        <Box
          sx={{
            bottom: 0,
            position: 'absolute',
            right: 0,
            left: 0,
            paddingBottom: '1rem'
          }}></Box>
      </Container>
    </>
  );
}
